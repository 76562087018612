import React from 'react';
import useDarkMode from 'use-dark-mode';
import Image from "gatsby-image"

const RssIcon = () => {
    const darkMode = useDarkMode(false);

    return (
        <div>
            {darkMode.value ? 
                // <Image src="../../content/assets/rss-white.png" alt="RSS feed" /> :
                // <Image src="../../content/assets/rss-black.png" alt="RSS feed" />
                <a class="rss-link" href="/rss.xml">RSS</a> : <a class="rss-link" href="/rss.xml">RSS</a>
            }
        </div>
    );
};

export default RssIcon;