import React from 'react';
import useDarkMode from 'use-dark-mode';

const DarkModeToggle = () => {
    const darkMode = useDarkMode(false);
    return (
        <div>
            {darkMode.value ? 
                <button type="button" class="dark-toggle" onClick={darkMode.disable}>☀</button> :
                <button type="button" class="dark-toggle" onClick={darkMode.enable}>☾</button>
            }
        </div>
    );
};

export default DarkModeToggle;